import React from 'react'
import { Layout } from 'components/Layout';
import { Top } from 'components/Top';
import { Bottom } from 'components/Bottom';
import { Helmet } from 'react-helmet';
import chumbador from 'images/produtos/chumbador.png';
import jaquetaCone from 'images/produtos/jaqueta-cone.png';
import chumbadorMedidas from 'images/produtos/chumbador-medidas.png';
import jaquetaConeMedidas from 'images/produtos/jaqueta-cone-medidas.png';
import {
  Container,
  ProductsContent,
  Title,
  Box,
  Highlight,
  ProductInfo,
  ProductDetails,
} from './styles'

const Products = () => (
  <Layout>
    <Helmet title="LCK - Produtos" />
    <Top />
    <Container>
      <h1>PRODUTOS IMPORTADOS DE PRIMEIRA QUALIDADE</h1>
      <ProductsContent>
        <div>
          <img src={chumbador} alt="chumbador cb com parafuso" />
          <Title>CHUMBADOR CB C/ PARAFUSO</Title>
        </div>
        <div>
          <img src={jaquetaCone} alt="jaqueta e cone" />
          <Title>JAQUETA E CONE</Title>
        </div>
      </ProductsContent>

      <Box>
        Excelentes condições de pagamento, preços competitivos e a
        entrega mais rápida da região. Garanta o giro dos seus produtos!
      </Box>

      <Highlight>
        Aproveite nossas condições e faça o seu pedido!
      </Highlight>

      <ProductInfo>
        <img src={chumbadorMedidas} alt="chumbador" />
        <div>
          <h3>Chumbador CB c/ parafuso:</h3>
          <ProductDetails>
            <span>1/4x2</span>
            <span>5/16x2.1/4</span>
            <span>3/8x2.1/2</span>
            <span>1/2x3</span>
          </ProductDetails>
        </div>
      </ProductInfo>

      <ProductInfo>
        <img src={jaquetaConeMedidas} alt="jaqueta e cone" />
        <div>
          <h3>Jaqueta e cone:</h3>
          <ProductDetails>
            <span>1/4</span>
            <span>5/16</span>
            <span>3/8</span>
            <span>1/2</span>
          </ProductDetails>
        </div>
      </ProductInfo>
    </Container>
    <Bottom />
  </Layout>

)

export default Products
