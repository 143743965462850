import styled from "@emotion/styled";

export const Container = styled.div`
  max-width: 1260px;
  margin: 80px auto;
  text-align: center;
  padding: 0 30px;

  h1 {
    font-size: 72px;
    -webkit-text-stroke: 3px #004aad;
    -webkit-text-fill-color: white;
  }

  @media (max-width: 900px) {
    padding: 10px 20px;
    h1 {
      font-size: 60px;
    }
  }

  @media (max-width: 480px) {
    margin: 30px auto;

    h1 {
      font-size: 30px;
      -webkit-text-stroke: 1px #004aad;
    }
  }
`

export const ProductsContent = styled.div`
  display: flex;
  justify-content: space-around;

  @media (max-width: 680px) {
    display: block;
  }
`

export const Title = styled.div`
  padding-top: 30px;
  max-width: 300px;
  font-size: 30px; 
  font-weight: bold;
  color: #004aad;

  @media (max-width: 900px) {
    font-size: 24px;
  }

  @media (max-width: 680px) {
    max-width: 100%;
    margin-bottom: 60px;
  }
`

export const Box = styled.div`
  background-color: #e8e8e8;
  margin: 30px;
  padding: 30px 60px;
  font-size: 30px;
  font-weight: 300;

  @media (max-width: 900px) {
    font-size: 24px;
  }
`

export const Highlight = styled.div`
  font-weight: bold;
  font-size: 60px;
  color: #004aad;
  max-width: 900px;
  margin: 60px auto;

  @media (max-width: 900px) {
    font-size: 30px;
  }
`

export const ProductInfo = styled.div`
  display: grid;
  grid-template-columns: 300px auto;
  text-align: left;
  max-width: 90%;
  margin: 0 auto 30px;

  img {
    padding-top: 30px;
  }

  @media (max-width: 850px) {
    display: block;
  }
`

export const ProductDetails = styled.div`
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
  column-gap: 10px;

  span {
    background-color: #cbcbcb;
    padding: 10px;
    text-align: center;
  }

  @media (max-width: 580px) {
    display: block;

    span {
      display: block;
      margin-bottom: 10px;
    }
  }
`
